import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@scbt-env/environment';
import { AuthService } from '@scbt-shared/services/auth.service';
import { Auth, authState, User } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { filter, switchMap, take, timeout } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {
	constructor(
		private scbTeamFbAuth: Auth,
		private authSrv: AuthService
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// requests to firebase functions only
		if (!request.url.includes(environment.functionsDomain)) {
			return next.handle(request);
		}

		return authState(this.scbTeamFbAuth).pipe(
			filter((user: User | null) => !!user),
			timeout({
				first: 10_000,
				with: () => {
					throw new Error('User not authenticated');
				},
			}),
			switchMap((user: User | null) => user.getIdToken()),
			switchMap((token) => {
				request = request.clone({
					setHeaders: {
						Authorization: `Bearer ${token}`,
					},
				});
				return next.handle(request);
			})
		);
	}
}
